<template>
  <v-list-item-group>
    <base-drawer-menu-item
      :icon="mdiCart"
      :title="$t('titles.tierPurchase')"
      :to="{ name: ROUTES.NAME.PURCHASE }"
    />
  </v-list-item-group>
</template>

<script>
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { ROUTES } from '@/constants';
import { mdiCart } from '@mdi/js';

export default {
  name: 'TheDrawerPurchase',
  components: { BaseDrawerMenuItem },
  data() {
    return {
      ROUTES,
      mdiCart,
    };
  },
};
</script>
