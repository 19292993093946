import { CONFIG, STATUS } from '@/constants';

const onBoardingManager = {
  getProgressStatus(forms, paymentStatus) {
    const statusBeforePayment = checkForms(forms);
    return statusBeforePayment
      ? statusBeforePayment
      : checkPayment(paymentStatus);
  },
};

export default onBoardingManager;

const hasModel = (ids, id) => ids.indexOf(id) > -1;
const hasPromo = (ids) => hasModel(ids, CONFIG.MODEL.ID.PROMO);
const hasPurchase = (ids) => hasModel(ids, CONFIG.MODEL.ID.PURCHASE);
const hasTos = (ids) => hasModel(ids, CONFIG.MODEL.ID.TOS);

const checkForms = (list) => {
  const ids = list.map((el) => el.model_id);
  if (!hasTos(ids)) {
    return STATUS.ONBOARDING.TOS;
  } else {
    if (hasPromo(ids)) {
      return STATUS.ONBOARDING.COMPLETE;
    }
    if (!hasPurchase(ids)) {
      return STATUS.ONBOARDING.PURCHASE;
    }
  }
  return null;
};

const checkPayment = (paymentStatus) => {
  switch (paymentStatus) {
    case STATUS.PAYMENT.PAID:
      return STATUS.ONBOARDING.COMPLETE;
    case STATUS.PAYMENT.PROCESSING:
      return STATUS.ONBOARDING.CHECKOUT_WAITING;
    case STATUS.PAYMENT.UNDEFINED:
      return STATUS.ONBOARDING.CHECKOUT_ERROR;
    default:
      return STATUS.ONBOARDING.PURCHASE;
  }
};
