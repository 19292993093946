import { ROUTES, STATUS } from '@/constants';
import { loadPage } from '../index';

const CheckoutErrorPage = loadPage('checkout/CheckoutErrorPage');
const CheckoutWaitingPage = loadPage('checkout/CheckoutWaitingPage');

const checkoutRoutes = [
  {
    path: ROUTES.PATH.CHECKOUT_CANCEL,
    meta: {
      auth: ROUTES.USER_ROLE.AUTHORIZED,
    },
    beforeEnter: (_to, _from, next) => {
      next(ROUTES.PATH.PURCHASE);
    },
  },
  {
    path: ROUTES.PATH.CHECKOUT_ERROR,
    name: ROUTES.NAME.CHECKOUT_ERROR,
    component: CheckoutErrorPage,
    meta: {
      auth: ROUTES.USER_ROLE.AUTHORIZED,
      tags: [STATUS.ONBOARDING.CHECKOUT_ERROR],
      title: ROUTES.TITLE.CHECKOUT_ERROR,
    },
  },
  {
    path: ROUTES.PATH.CHECKOUT_WAITING,
    name: ROUTES.NAME.CHECKOUT_WAITING,
    component: CheckoutWaitingPage,
    meta: {
      auth: ROUTES.USER_ROLE.AUTHORIZED,
      tags: [STATUS.ONBOARDING.CHECKOUT_WAITING],
      title: ROUTES.TITLE.CHECKOUT_WAITING,
    },
  },
];

export default checkoutRoutes;
