const STATUS = Object.freeze({
  ONBOARDING: {
    CHECKOUT_ERROR: 'checkout-error',
    CHECKOUT_WAITING: 'checkout-waiting',
    COMPLETE: 'complete',
    PURCHASE: 'purchase',
    TOS: 'tos',
  },
  PAYMENT: {
    PAID: 'paid',
    PROCESSING: 'processing',
    UNDEFINED: 'undefined',
    UNPAID: 'unpaid',
  },
});

export default STATUS;
