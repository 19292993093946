import Keycloak from 'keycloak-js';
import { modelsApi } from '@/apis';

import { CONFIG, KEYCLOAK_CONFIG } from '@/constants';

const initOptions = {
  url: KEYCLOAK_CONFIG.URL,
  realm: KEYCLOAK_CONFIG.REALM,
  clientId: KEYCLOAK_CONFIG.CLIENT_ID,
};

const keycloak = new Keycloak(initOptions);

let refreshIntervalId;

export default {
  async initApp({ commit, dispatch }) {
    commit('setLoading', true);
    await dispatch('authenticate');
    commit('setLoading', false);
  },
  async authenticate({ commit, dispatch }) {
    await dispatch('setupClient');
    if (keycloak.authenticated) {
      commit('setToken', keycloak.token);
      await dispatch('authorise');
    }
  },
  async setupClient({ commit, dispatch }) {
    try {
      await keycloak.init({
        onLoad: KEYCLOAK_CONFIG.ON_LOAD,
        checkLoginIframe: false,
      });
      refreshIntervalId = setInterval(async () => {
        await dispatch('refreshToken');
      }, KEYCLOAK_CONFIG.REFRESH_TOKEN_TIME * 1000);
    } catch (error) {
      commit('setToken', '');
    }
  },
  async refreshToken({ commit }) {
    try {
      const response = await keycloak.updateToken(70);
      if (response) {
        commit('setToken', keycloak.token);
      }
    } catch (error) {
      commit('setToken', '');
    }
  },
  async authorise({ dispatch, getters, rootGetters }) {
    const requests = [
      await dispatch('fetchModels'),
      await dispatch('fetchProfile'),
    ];
    await Promise.all(requests);
    const profile = getters['profile'];
    await dispatch('onboarding/getForms', profile.id, { root: true });
    const sessionId = rootGetters['onboarding/sessionId'];
    if (sessionId)
      await dispatch('onboarding/getPaymentStatus', sessionId, {
        root: true,
      });
  },
  async fetchModels({ dispatch }) {
    const requests = [];
    for (const id in CONFIG.MODEL.ID) {
      requests.push(dispatch('getModel', CONFIG.MODEL.ID[id]));
    }
    await Promise.all(requests);
  },
  async getModel({ commit, rootGetters }, model_id) {
    const token = rootGetters['token'];
    try {
      const response = await modelsApi.getModel(model_id, token);
      commit('addModel', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchProfile({ commit }) {
    try {
      const profile = await keycloak.loadUserProfile();
      commit('setProfile', profile);
      return profile;
    } catch (error) {
      commit('setError', error);
    }
  },
  async logOut({ commit }) {
    clearInterval(refreshIntervalId);
    try {
      await keycloak.logout();
      commit('reset');
    } catch (error) {
      commit('setError', error);
    }
  },
};
