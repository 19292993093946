import { configurationsApi } from '@/apis';
import CONFIG from '@/constants/config-constant';

export default {
  async fetchForms({ commit, rootGetters }) {
    const profile = rootGetters['profile'];
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await configurationsApi.getConfigurationUserForms(
        {
          configuration_id: CONFIG.ID,
          user_id: profile.id,
        },
        token,
      );
      commit('setList', response.data.list);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  async fetchFormDetail({ commit, rootGetters }, formId) {
    const profile = rootGetters['profile'];
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await configurationsApi.getConfigurationFormDetail(
        {
          configuration_id: CONFIG.ID,
          user_id: profile.id,
          form_id: formId,
        },
        token,
      );
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
};
