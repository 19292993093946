import axios from 'axios';

import APIS from '@/constants/apis-constant';

const foxPayApi = {
  postCreateCheckout(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/create-checkout',
      method: 'post',
      headers,
      baseURL: APIS.FOX_PAY,
      data,
    });
  },
  getSession(sessionId, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/session',
      method: 'get',
      headers,
      baseURL: APIS.FOX_PAY,
      params: {
        session_id: sessionId,
      },
    });
  },
};

export default foxPayApi;
