<template>
  <v-list-item-group>
    <v-list-item>
      <v-list-item-icon>
        <country-flag :country="locale.flag" size="normal" />
      </v-list-item-icon>
      <v-list-item-title>
        <v-select
          :hint="`${locale.text}, ${locale.label}`"
          :items="items"
          :placeholder="$t('labels.lang')"
          dense
          item-text="text"
          item-value="value"
          return-object
          v-model="locale"
          @change="onChangeLocale"
        >
          <template #selection="{ item }">
            <span class="text-subtitle-2">{{ item.text }}</span>
          </template>
        </v-select>
      </v-list-item-title>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
import CountryFlag from 'vue-country-flag';

import { LOCALE } from '@/constants';

export default {
  name: 'TheDrawerLocale',
  components: { CountryFlag },
  data() {
    return {
      locale: null,
    };
  },
  computed: {
    defaultLocale() {
      return LOCALE.LOCALES.find((locale) => locale.default);
    },
    items() {
      return LOCALE.LOCALES;
    },
  },
  created() {
    this.locale = localStorage.getItem(LOCALE.BROWSER_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(LOCALE.BROWSER_STORAGE_KEY))
      : this.defaultLocale;
    this.$i18n.locale = this.locale.value;
  },
  methods: {
    onChangeLocale() {
      this.$i18n.locale = this.locale.value;
      localStorage.setItem(
        LOCALE.BROWSER_STORAGE_KEY,
        JSON.stringify(this.locale),
      );
    },
  },
};
</script>
