<template>
  <v-list-item-group>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon color="primary" class="text-h3">
          {{ mdiAccountCircleOutline }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="title pb-1">
          <span v-text="fullName" />
        </v-list-item-title>
        <v-list-item-subtitle>
          <span v-text="email" />
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="ma-1" />
  </v-list-item-group>
</template>

<script>
import { mdiAccountCircleOutline } from '@mdi/js';
import { mapGetters } from 'vuex';

export default {
  name: 'TheDrawerAccount',
  data() {
    return { mdiAccountCircleOutline };
  },
  computed: {
    ...mapGetters(['profile']),
    email() {
      return this.profile && this.profile.email ? this.profile.email : '';
    },
    fullName() {
      return this.profile && this.profile.firstName && this.profile.lastName
        ? `${this.profile.firstName} ${this.profile.lastName}`
        : '';
    },
  },
};
</script>
