<template>
  <v-list-item-group>
    <base-drawer-menu-item
      :icon="mdiHome"
      :title="$t('titles.home')"
      :to="{ name: ROUTES.NAME.HOME }"
    />
  </v-list-item-group>
</template>

<script>
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { ROUTES } from '@/constants';
import { mdiHome } from '@mdi/js';

export default {
  name: 'TheDrawerRoutes',
  components: { BaseDrawerMenuItem },
  data() {
    return {
      ROUTES,
      mdiHome,
    };
  },
};
</script>
