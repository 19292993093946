import { ROUTES, STATUS } from '@/constants';
import { loadPage } from '../index';

const PrivacyPage = loadPage('PrivacyPage');

const privacyRoute = {
  path: ROUTES.PATH.PRIVACY,
  name: ROUTES.NAME.PRIVACY,
  component: PrivacyPage,
  meta: {
    auth: ROUTES.USER_ROLE.ANY,
    tags: [STATUS.ONBOARDING.PRIVACY],
    title: ROUTES.TITLE.PRIVACY,
  },
};

export default privacyRoute;
