<template>
  <v-list-item-group>
    <base-drawer-menu-item
      :icon="mdiFileDocumentEdit"
      :title="$t('titles.agreements')"
      :to="{ name: ROUTES.NAME.AGREEMENTS }"
    />
  </v-list-item-group>
</template>

<script>
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { ROUTES } from '@/constants';
import { mdiFileDocumentEdit } from '@mdi/js';

export default {
  name: 'TheDrawerAgreements',
  components: { BaseDrawerMenuItem },
  data() {
    return {
      ROUTES,
      mdiFileDocumentEdit,
    };
  },
};
</script>
