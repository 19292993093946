import { v4 as uuidv4 } from 'uuid';
import { configurationsApi, foxPayApi, modelsApi } from '@/apis';
import CONFIG from '@/constants/config-constant';
import { formHandler } from '@/utils';

export default {
  async fetchModel({ commit, rootGetters }, model_id) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await modelsApi.getModel(model_id, token);
      commit('setModel', response.data);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  async getForms({ commit, rootGetters }, userId) {
    const token = rootGetters['token'];
    try {
      const response = await configurationsApi.getConfigurationUserForms(
        {
          configuration_id: CONFIG.ID,
          user_id: userId,
        },
        token,
      );
      commit('setForms', response.data.list);
    } catch (error) {
      commit('setError', error);
    }
  },
  async setForm({ commit, rootGetters }, { userId, data }) {
    const token = rootGetters['token'];
    try {
      await configurationsApi.postConfigurationUserForms(
        {
          configuration_id: CONFIG.ID,
          user_id: userId,
          data,
        },
        token,
      );
    } catch (error) {
      commit('setError', error);
    }
  },
  async saveForm({ commit, dispatch, rootGetters }, { data, modelId }) {
    const profile = rootGetters['profile'];
    const formId = uuidv4();
    data.formID = formId;
    const form = formHandler.build({
      formId,
      modelId,
      profileId: profile.id,
      data,
    });
    commit('setLoading', true);
    await dispatch('setForm', { userId: profile.id, data: form });
    await dispatch('getForms', profile.id);
    commit('setLoading', false);
  },
  async purchase({ commit, dispatch, rootGetters }, data) {
    const token = rootGetters['token'];
    const profile = rootGetters['profile'];
    const formId = uuidv4();
    commit('setLoading', true);
    const session = await dispatch('createCheckout', {
      token,
      productId: data.product_id,
    });
    data.purchase_session_id = session.session_id;
    const form = formHandler.build({
      formId,
      modelId: CONFIG.MODEL.ID.PURCHASE,
      profileId: profile.id,
      data,
    });
    await dispatch('setForm', { userId: profile.id, data: form });
    await dispatch('getForms', profile.id);
    commit('setLoading', false);
  },
  async createCheckout({ commit }, { token, productId }) {
    const data = { stripe_product_id: productId };
    try {
      const response = await foxPayApi.postCreateCheckout(token, data);
      commit('setSession', response.data);
      return response.data;
    } catch (error) {
      commit('setError', error);
    }
  },
  async checkPaymentStatus({ commit, dispatch }, sessionId) {
    commit('setLoading', true);
    dispatch('getPaymentStatus', sessionId);
    commit('setLoading', false);
  },
  async getPaymentStatus({ commit, rootGetters }, sessionId) {
    const token = rootGetters['token'];
    try {
      const response = await foxPayApi.getSession(sessionId, token);
      commit('setPaymentStatus', response.data.payment_status);
    } catch (error) {
      commit('setError', error);
    }
  },
};
