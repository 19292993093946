const formHandler = {
  build({ formId, modelId, profileId, data }) {
    const datum = Date.now();
    const fileName = `${modelId}-date_${datum}-user_${profileId}.json`;
    const file = new File([JSON.stringify(data)], fileName);
    return {
      model_id: modelId,
      form_id: formId,
      content: file,
    };
  },
  decode(text) {
    return JSON.parse(window.atob(text));
  },
  encode(obj) {
    return window.btoa(JSON.stringify(obj));
  },
};

export default formHandler;
