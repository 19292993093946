<template>
  <v-list dense nav>
    <the-drawer-locale />
    <v-divider class="ma-1" />
    <the-drawer-account v-if="isAuthenticated" />
    <the-drawer-signout v-if="isAuthenticated" />
    <the-drawer-routes v-if="hasTier" />
    <the-drawer-purchase v-if="isPurchase" />
    <the-drawer-agreements v-if="hasAgreements" />
  </v-list>
</template>

<script>
import TheDrawerAccount from './drawer/TheDrawerAccount.vue';
import TheDrawerAgreements from './drawer/TheDrawerAgreements.vue';
import TheDrawerLocale from './drawer/TheDrawerLocale.vue';
import TheDrawerPurchase from './drawer/TheDrawerPurchase.vue';
import TheDrawerRoutes from './drawer/TheDrawerRoutes.vue';
import TheDrawerSignout from './drawer/TheDrawerSignout.vue';

import { STATUS } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'TheDrawer',
  components: {
    TheDrawerAccount,
    TheDrawerAgreements,
    TheDrawerLocale,
    TheDrawerPurchase,
    TheDrawerRoutes,
    TheDrawerSignout,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapGetters('onboarding', ['progressStatus']),
    hasAgreements() {
      return this.progressStatus !== STATUS.ONBOARDING.TOS;
    },
    hasTier() {
      return this.progressStatus === STATUS.ONBOARDING.COMPLETE;
    },
    isPurchase() {
      return this.progressStatus === STATUS.ONBOARDING.PURCHASE;
    },
  },
};
</script>
