import Vue from 'vue';
import Vuex from 'vuex';
import agreements from './agreements';
import application from './application';
import onboarding from './onboarding';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // root
    application,
    // namespaced
    agreements,
    onboarding,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
