const ROUTES = Object.freeze({
  PATH: {
    AGREEMENTS: '/agreements',
    AGREEMENTS_LIST: 'list',
    AGREEMENTS_DETAIL: 'detail/:id',
    CHECKOUT_CANCEL: '/checkout-cancel',
    CHECKOUT_ERROR: '/checkout-error',
    CHECKOUT_WAITING: '/checkout-waiting',
    HOME: '/home',
    LOGIN: '/login',
    NOT_FOUND: '/:notFound(.*)',
    PRIVACY: '/terms/privacy',
    PURCHASE: '/purchase',
    ROOT: '/',
    TOS: '/tos',
  },
  NAME: {
    AGREEMENTS: 'Agreements',
    AGREEMENTS_LIST: 'AgreementsList',
    AGREEMENTS_DETAIL: 'AgreementsDetail',
    CHECKOUT_CANCEL: 'CheckoutCancel',
    CHECKOUT_ERROR: 'CheckoutError',
    CHECKOUT_WAITING: 'CheckoutWaiting',
    HOME: 'Home',
    NOT_FOUND: 'NotFound',
    PRIVACY: 'Privacy',
    PURCHASE: 'Purchase',
    TOS: 'Tos',
  },
  TITLE: {
    AGREEMENTS: 'Agreements',
    AGREEMENTS_LIST: 'Agreements List',
    AGREEMENTS_DETAIL: 'Agreements Detail',
    CHECKOUT_CANCEL: 'Checkout Cancel',
    CHECKOUT_ERROR: 'Checkout Error',
    CHECKOUT_WAITING: 'Checkout Waiting',
    HOME: 'Home',
    NOT_FOUND: 'Not Found',
    PRIVACY: 'Privacy',
    PURCHASE: 'purchase',
    TOS: 'TOS',
  },
  USER_ROLE: {
    ANY: 'any',
    AUTHORIZED: 'authorized',
    NOT_AUTHORIZED: 'notAuthorized',
  },
});

export default ROUTES;
