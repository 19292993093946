const BACKEND = process.env.VUE_APP_BACKEND;
const BASE_PATH = `${BACKEND}/api/v1`;
const FOX_PAY = process.env.VUE_APP_FOX_PAY;
const FOX_PAY_PATH = `${FOX_PAY}/v1`;

const APIS = Object.freeze({
  CONFIGURATIONS: `${BASE_PATH}/configurations`,
  FILES: `${BASE_PATH}/files`,
  MODELS: `${BASE_PATH}/models`,
  FOX_PAY: `${FOX_PAY_PATH}/stripe`,
});

export default APIS;
