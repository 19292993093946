import { ROUTES, STATUS } from '@/constants';
import { loadPage } from '../index';

const PurchasePage = loadPage('PurchasePage');

const purchaseRoute = {
  path: ROUTES.PATH.PURCHASE,
  name: ROUTES.NAME.PURCHASE,
  component: PurchasePage,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [STATUS.ONBOARDING.PURCHASE],
    title: ROUTES.TITLE.PURCHASE,
  },
};

export default purchaseRoute;
