import axios from 'axios';

import APIS from '@/constants/apis-constant';

const modelsApi = {
  getModel(model_id, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${model_id}`,
      method: 'get',
      headers,
      baseURL: APIS.MODELS,
    });
  },
};

export default modelsApi;
